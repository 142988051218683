import { useEffect, useState } from "react";
import ModalPayments from "./ModalPayments";
import { Card } from "reactstrap";
import socialNetworkImage from "@/assets/images/socialNetwork.png";
import checkRed from "@/assets/images/checkRed.png";

const ProductCards = ({ e, pillTab, currentMsisdn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const [planDetails, setPlanDetails] = useState(null);

  useEffect(() => {
    const parsedData = parseHtmlData(e.description);
    setPlanDetails(parsedData);
  }, []);

  const parseHtmlData = (html) => {
    const planDuration = html.match(/Disfruta por (\d+) (MESES|días)/i)?.[0];
    const dataAllowance = html.match(/<strong>(\d+GB)<\/strong>/)?.[1];
    return {
      planDuration,
      dataAllowance,
      benefitDetails: {
        description: html.match(/para navegar\*/)?.[0],
        socialNetworks: html.match(/Redes Sociales/)?.[0],
        unlimitedCalls: html.match(/ILIMITADAS/)?.[0],
        regions: html.match(/Nacionales, EUA Y Canad\u00e1/)?.[0],
      },
    };
  };

  return (
    <Card
      className="text-center cursor-pointer"
      style={{ width: "330px", borderRadius: "30px" }}
    >
      <ModalPayments
        isOpen={isOpen}
        onToggle={handleOpen}
        e={e}
        currentMsisdn={currentMsisdn}
      />

      <div
        style={{
          flex: 1,
          background: "linear-gradient(240deg, #0a3359 0%, #131a20 50%)",
          borderRadius: "30px",
          padding: "16px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>
            {e.name.split(pillTab.value)[1]}
          </h2>
          {planDetails && (
            <div style={{ textAlign: "center", paddingTop: "8px" }}>
              <p
                style={{ fontSize: "28px" , fontWeight: "bold", color: "white" }}
              >
                {planDetails.dataAllowance}
              </p>
              <p style={{ fontSize: "40px", fontWeight: "bold", color: "red" }}>
                ${e.amount}
              </p>
              <p style={{ fontSize: "20px", color: "white" }}>
                {planDetails.planDuration}
              </p>
              <p
                style={{ fontSize: "28px", fontWeight: "bold", color: "white" }}
              >
                {planDetails.dataAllowance}
              </p>
              <p style={{ fontSize: "20px", color: "white" }}>Para navegar *</p>
              <div style={{ position: "relative", width: "100%", height: "60px" }}>
                <img
                  src={socialNetworkImage} // Ensure this path is correct
                  alt="social"
                  style={{
                  position: "absolute",
                  right: "-10px",
                  top: "50%",
                  transform: "translateY(-15px)",
                  maxWidth: "100%",    // Allow image to scale based on the container width
                  height: "auto",     // Maintain aspect ratio
                }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "24px",
                }}
              >
                <img
                  src={checkRed}
                  alt="check"
                  style={{ width: "25px", height: "25px", marginRight: "8px" }}
                />
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Minutos y Sms Incluidos
                </p>
              </div>
              <h3
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "white",
                  paddingTop: "24px",
                }}
              >
                {planDetails.benefitDetails.regions}
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "8px 0 16px",
                  width: "100%",
                }}
              >
                <button
                  onClick={handleOpen}
                  style={{
                    backgroundColor: "red",
                    padding: "10px",
                    width: "120px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  ¡Lo quiero!
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProductCards;
